body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ter-feat-four-flex .ter-card__body {
  height: auto !important;
}
.ter-feat-four-flex__card-container {
  width: 100%;
}
.ter-list-feat-main--custom {
  overflow: auto !important;
}

.ter-hero-three {
  width: 100%;
}

.ter-hero-three__video-wrapper iframe {
  max-width: 1060px !important;
  max-height: 600px !important;
}