/**
 * Depth
 */

$depth-scale: (
	1,
	2,
	3,
	4,
);

@each $level in $depth-scale {
    .depth-#{$level} {
        @include depth($level);
    }
}