@import "../../global-styles/global-terra.scss";

.App {
  display: grid;
  min-height: 100vh;
  overflow: hidden !important;

  &--override-language-switcher {
    .ter-navbar-top__location,
    .ter-navbar-top__globe,
    .ter-top-mobile-nav__location-wrapper {
      &:hover {
        cursor: auto !important;
      }
      //display: none;
    }
  }

  .ter-footer {
    align-self: end;

    &__secondary-link-label {
      margin-bottom: 9px;
    }
  }
  .browser-banner {
    position: relative;
    top: 160px;
    width: 100vw;
    position: fixed;
    z-index: 10;
  }
  .adjust-banner-position {
    transition: 0.2s;
    top: 45px;
  }
  .ter-alert {
    background-color: #f57362;
    color: white;
    z-index: 10;
  }
  .ter-navbar__fullscreen-dark-backer {
    // TODO - Fix needs to go in LunaOne
    top: 0;
  }

  .ter-navbar-top {
    @media screen and (max-width: 900px) {
      grid-template-columns: 220px 1fr 50px !important;
    }
  }

  .luna-loading {
    margin: auto;
    align-self: center;
  }

  .ter-textarea {
    line-height: 24px !important;
    padding: 16px;
  }
}

.ter-navbar-top__right > .ter-navbar-top__search-wrapper {
  display: none;
}
