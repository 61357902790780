.ter-feat-one,
.ter-feat-two,
.ter-feat-three,
.ter-feat-four,
.ter-feat-five,
.ter-feat-six,
.ter-feat-seven,
.ter-feat-eight,
.ter-feat9,
.ter-feat-ten,
.ter-hero-two,
.ter-hero-four,
.ter-hero-five,
.ter-feat-four-flex {
  &__background-image {
    transition: 0.1s;
    // background-size: contain;
    // This is potentially bad for parallax performance optmization

    background-repeat: no-repeat;
  }
  overflow: hidden;
}
