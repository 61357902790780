/**
 * Font Family
 */


.font-sans-serif {
	font-family: $font-sans-serif;
}

.font-serif {
	font-family: $font-serif;
}

.font-code {
	font-family: $font-code;
}