
// flex-direction

.flex-row {
	flex-direction: row;
}

.flex-row-reverse {
	flex-direction: row-reverse;
}

.flex-column {
	flex-direction: column;
}

.flex-column-reverse {
	flex-direction: column-reverse;
}


// flex-wrap

.flex-wrap {
	flex-wrap: wrap;
}

.flex-nowrap {
	flex-wrap: nowrap;
}

.flex-wrap-reverse {
	flex-wrap: wrap-reverse;
}


// flexing

.flex-1 {
	flex: 1;
}

.flex-0 {
	flex: 0;
}


// justify-content

.justify-content-flex-start {
    justify-content: flex-start;
}

.justify-content-flex-end {
    justify-content: flex-end;
}

.justify-content-center {
	justify-content: center;
}

.justify-content-space-between {
	justify-content: space-between;
}

.justify-content-space-around {
	justify-content: space-around;
}


// align-items

.align-items-start {
    align-items: flex-start;
}

.align-items-end {
    align-items: flex-end;
}

.align-items-center {
	align-items: center;
}

.align-items-baseline {
	align-items: baseline;
}

.align-items-stretch {
	align-items: stretch;
}


// align-self

.align-self-start {
    align-self: flex-start;
}

.align-self-end {
    align-self: flex-end;
}

.align-self-center {
	align-self: center;
}

.align-self-baseline {
	align-self: baseline;
}

.align-self-stretch {
	align-self: stretch;
}


// align-content

.align-content-start {
    align-content: flex-start;
}

.align-content-end {
    align-content: flex-end;
}

.align-content-center {
	align-content: center;
}

.align-content-between {
	align-content: space-between;
}

.align-content-around {
	align-content: space-around;
}

.align-content-stretch {
	align-content: stretch;
}